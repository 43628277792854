var $ = require('jquery'); 
var emailValidationHandler = { 
     emailValidation: function _emailValidation(email,type){ 
     var credential = email.trim(); 
     var emailValidationRegex = /^[_0-9A-Za-z-.@ ]+$/; 
     if(credential==='' || emailValidationRegex.test(credential)){ 
        return true; 
     } 
     else{ 
        return false; 
         }            
    } 
} 
module.exports = emailValidationHandler;
