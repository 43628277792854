/* Component Dependencies  */
var signInTemplate = require('templates/signIn.hbs');
var signInPanelTemplate = require('templates/signIn-panel.hbs');
var signInOverlayTemplate = require('templates/signIn-overlay.hbs');
var signInHomeTemplate = require('templates/signIn-home.hbs');
var signInHomeHSIATemplate = require('templates/signIn-home-HSIA.hbs');
var signInLookUpReservationTemplate = require('templates/signIn-lookUpReservation.hbs');
var signInGuestInfoPopUpTemplate = require('templates/signIn-guestInfoPopUp.hbs');
var signInReservationLoginTemplate = require('templates/signIn-reservationLogIn.hbs');
var signInOrderAwardsTemplate = require('templates/signIn-orderAwards.hbs');
var signInGuestInfoPopUpERSTemplate = require('templates/signIn-guestInfoPopUpERS.hbs');
var signInerfpOverlay=require('templates/signIn-erfpOverlay.hbs');
var signInSPGTemplate = require('templates/signIn-spg.hbs');
var signInRoomingListTemplate = require('templates/signIn-roomingList.hbs');
var signInHeaderOverlayTemplate = require('templates/signIn-headerOverlay.hbs');
var signInClearRememberedTemplate = require('templates/signIn-clearRemembered.hbs');
var signInEnrollmentHeadingTemplate = require('templates/signIn-enrollmentHeading.hbs');
var signInOfferNextGenTemplate = require('templates/signIn-offerNextGen.hbs');
var signInOcjOverlayTemplate = require('templates/signIn-ocjOverlay.hbs')
var emailValidationHandler = require('utils/emailValidation.js');

var PubSub = require('libs/pub-sub');
var Popup = require('libs/popup');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var serializeObject = require('libs/serialize-object');
var AutoComplete = require('utils/autoCompleteTypeHandler');
var ResponsiveUtils = require('utils/responsiveUtils');

PubSub.register(AriesComponent.extend({
    type: 'signIn',
    // subscribe: {
    //     'WINDOW_LOAD_EVENT': 'addGigyaScript'
    // },
    template: {
        'signIn': signInTemplate,
        'signIn-overlay': signInOverlayTemplate,
        'signIn-panel': signInPanelTemplate,
        'signIn-home': signInHomeTemplate,
        'signIn-home-HSIA': signInHomeHSIATemplate,
        'signIn-lookUpReservation': signInLookUpReservationTemplate,
        'signIn-guestInfoPopUp': signInGuestInfoPopUpTemplate,
        'signIn-reservationLogIn': signInReservationLoginTemplate,
        'signIn-orderAwards': signInOrderAwardsTemplate,
        'signIn-guestInfoPopUpERS' : signInGuestInfoPopUpERSTemplate,
        'signIn-spg' : signInSPGTemplate,
        'signIn-roomingList' : signInRoomingListTemplate,
        'signIn-erfpOverlay': signInerfpOverlay,
        'signIn-headerOverlay': signInHeaderOverlayTemplate,
        'signIn-clearRemebered': signInClearRememberedTemplate,
        'signIn-enrollmentHeading': signInEnrollmentHeadingTemplate,
        'signIn-offerNextGen': signInOfferNextGenTemplate,
        'signIn-ocjOverlay': signInOcjOverlayTemplate
    },

    unBindEvents: function($) {
        $('[data-component-id="' + this.$el.data('component-id') + '"] *').off();
        $('[data-component-id="' + this.$el.data('component-id') + '"]').off();
    },
    events: {
        'click .js-guest-btn': 'closeOverlaySignIn',
        'focus .signin-password': 'removeReadOnly',
        'click .js-btn-submit': 'validationEmail',
        'focus .signin-username': 'removeReadOnly',
        'click .js-sign-in-ocj-btn': 'formSubmitHandler',
        'click .js-clear-remembered-user': 'clearUser',
        'click .facebook-list-item' : "facebookAccordionShowHide",
    },
    bindEvents: function() {
        'use strict';
        // code specific to signIn component here
        var _self = this;

        if (_self.$el.find('.main-signin-form').length) {
            _self.$el.find('#field-user-id').focus();
        }
        /*Sign-in form submit if form is not hidden*/
        if (_self.$el.find('form[name=\'signInForm\']').get(0)) {
            _self.$el.find('form[name=\'signInForm\']').submit(function() {
                _self.$el.find('button').attr('disabled', 'disabled');
                _self.$el.find('button').addClass('is-disabled');
                return _self.logIn();
            });
            _self.logInSetInitialFocus();

        } else {
            // Prevent multiple form post calls
            _self.$el.parent().find('form').submit(function() {
                _self.$el.find('button').attr('disabled', 'disabled');
                _self.$el.find('button').addClass('is-disabled');
            });
        }
        _self.$el.find('.js-signin-btn a, .js-cancel-link').on('click', function(e) {
            _self.rewardsSignBtnClicks();
            e.preventDefault();
        });
        _self.bindingMouseEvents(_self.$el.find('.m-header-myaccount'));
        _self.whatIsThisPopUp();
        _self.orderAwardsPopUp();

        if (_self.$el.find('input[name="processUpdatedReturnURL"]').length) {
            _self.updateReturnToUrl();
        }
        _self.$el.find('noscript').remove();

        // if (_self.$el.find('.tile-univnav-sign-in').length) {
        //     _self.pubsub.publish('WINDOW_LOAD_EVENT');
        // }
        
        /** 
         *   This is new Implementation for HSIA Sheraton for new UI 
         */
        if (_self.$el.hasClass('hsia')) {
            _self.$el.closest('.mi-sub-section')
            .removeClass('t-bg-standard-10 l-margin-subsection-bottom-responsive-large')
            .addClass('sign-in-home-hsia');
            _self.copyMessageFromRewardsMemberRateTile();
        }
        /* Initiating responsive utlis only for one click join modal */
        if(_self.$el.hasClass('tile-sign-in-ocj-overlay')) {
            _self.initResponsiveUtils();
            _self.orientationChangeEventHandler();
        }
    },
    /**
     * This function is used to initiate responsive utils
    */
    initResponsiveUtils: function() {
        this.responsiveUtils = ResponsiveUtils.create({
        orientationChangeEventHandler: _.bind(this.orientationChangeEventHandler, this)
        });
        this.responsiveUtils.init();
    },

    closeOverlaySignIn: function(event) {
        event.preventDefault();
        this.pubsub.publish('POPUP_CLOSE');
    },
    /**
     * This method bind events on click of submit button and publish a pubsub event
     * on callback of pubsub event updates returnUrl hidden input and submit form
     * @return {boolean} operation success or not
     */
    updateReturnToUrl: function _updateReturnToUrl() {
        var _self = this;
        _self.pubsub.publish('UPDATE_RETURNTO_URL', [function(returnToURL) {
            _self.$el.find('input[name="returnUrl"]').val(returnToURL);
            var $createOnlineAccount = _self.$el.find('.create-online-account');
            $createOnlineAccount.attr('href', $createOnlineAccount.attr('href') + '?returnUrl=' + encodeURIComponent(returnToURL));
        }]);
        return true;
    },

    copyMessageFromRewardsMemberRateTile: function (){
        var _self = this;
        var message = $('.tile-marriott-rewards-member-rate.hsia').find('h4').text();
        _self.$el.find('.rate-message').text(message)
    },

    orderAwardsPopUp: function() {
        if (this.$el.find(".join-now").length) {
            var orderAwards = new Popup({
                selector: '.join-now',
                ajaxLoaded: true,
                url: '/award-order.mi',
                sourceBlock: '.js-popover',
                addBorder: true,
                borderClass: 'blue-border-wrap',
                mainClass: 'm-modal t-modal-med'
            });
            orderAwards.register();
        }
    },

    bindingMouseEvents: function(element) {
        $(element).on({
            mouseenter: function() {
                element.addClass('is-focused');
                element.data('hasFocus', true);
                element.data('hasMouseOver', true);
                var hover = '',
                    $button = element.find('button.m-button-darkGray');
                if (!$button.length) {
                    hover = '-hover';
                    $button = element.find('button.m-button-darkGray-hover');
                }
                $button.removeClass('m-button-darkGray' + hover).addClass('m-button-default' + hover);
            },
            mouseleave: function() {
                if (!element.find(':input').is(':focus')) {
                    element.removeClass('is-focused');
                    element.data('hasFocus', false);
                    element.find('button.m-button-default').removeClass('m-button-default').addClass('m-button-darkGray');
                }
                element.data('hasMouseOver', false);
            },
            focusin: function() {
                if (!element.data('hasFocus')) {
                    element.addClass('is-focused');
                    element.data('hasFocus', true);
                    element.find('button.m-button-darkGray').removeClass('m-button-darkGray').addClass('m-button-default');
                }
            },
            focusout: function() {
                if (!element.data('hasMouseOver')) {
                    // Hide only when the new focused element is not inside the .m-header-myaccount container
                    // Delay required to focus hidden elements when tabbing in Firefox
                    setTimeout(function() {
                        if (!element.find(':input,a').is(':focus')) {
                            element.removeClass('is-focused');
                            element.data('hasFocus', false);
                            element.find('button.m-button-default').removeClass('m-button-default').addClass('m-button-darkGray');
                        }
                    }, 1);
                }
            }
        });
    },
    logIn: function() {
        var userNamePrefix = this.$el.find('form[name=\'signInForm\']').find('input[name=\'userNamePrefix\']').val();
        var userName = this.$el.find('form[name=\'signInForm\']').find('input[name=\'visibleUserName\']').val();

        while (userName.indexOf(' ') >= 0) {
            userName = userName.replace(' ', '');
        }
        this.$el.find('form[name=\'signInForm\']').find('input[name=\'j_username\']').val(userNamePrefix + userName);

        // Submit the user name and password
        return true;
    },
    logInSetInitialFocus: function() {
        if (this.$el.find('form[name=\'signInForm\']').get(0)) {
            var $visibleUserName = this.$el.find('form[name=\'signInForm\']').find('input[name=\'visibleUserName\']');
            if ($visibleUserName.attr('type') === 'hidden') {
                this.$el.find('form[name=\'signInForm\']').find('input[name=\'j_password\']').focus();
            } else {
                this.$el.find('form[name=\'signInForm\']').find('input[name=\'visibleUserName\']').focus();
            }
        }
    },

    rewardsSignBtnClicks: function() {
        var _self = this;
        _self.$el.find('.js-rewards-bar, .js-anonymous-form').slideToggle(800);
    },
    whatIsThisPopUp: function() {
        if (this.$el.find(".mi-popover").length) {
            var whatIsThis = new Popup({
                selector: '.remember-me .mi-popover, #remember-me-link.mi-popover',
                parent: 'signIn',
                sourceBlock: '.modal-content',
                mainClass: 'm-modal t-modal-med'
            });
            whatIsThis.register();
        }
    },
    
    removeReadOnly: function(event){
        var field = this.$el.find(event.target);

        if(field.attr('readonly')){
         field.removeAttr('readonly').focus();
        }
    },
    validationEmail: function _validationEmail(e){
        var mobileAuthEnabledEmailValidation = $('.js-emailValidation').val();
        var mobileAuthEnabledRegex = /^[0-9+ ]+$/;
        var isMobileAuthEnabledEmailValidation = mobileAuthEnabledRegex.test(mobileAuthEnabledEmailValidation);
        var mobileAuthEnabled= $('.js-emailValidation').data('mobile-auth-enabled');
        if(mobileAuthEnabled==true){
            if(!isMobileAuthEnabledEmailValidation){
              if(emailValidationHandler.emailValidation($('.js-emailValidation').val(), 'all')){
                       $('#email-validation').addClass('l-display-none');
                    }
                    else{
                       $('#email-validation').removeClass('l-display-none');
                       if($('.m-message-box.m-message-box-error.l-clear')) {
                             $('.m-message-box.m-message-box-error.l-clear').addClass('l-display-none');
                       }  
                        e.preventDefault(); 
                }
            }
        }
        else{
            if(emailValidationHandler.emailValidation($('.js-emailValidation').val(), 'all')){
                   $('#email-validation').addClass('l-display-none');
                }
                else{
                   $('#email-validation').removeClass('l-display-none');
                   if($('.m-message-box.m-message-box-error.l-clear')) {
                         $('.m-message-box.m-message-box-error.l-clear').addClass('l-display-none');
                   }
                    e.preventDefault();
            }
    }
    },
    /**
     *  This function is for making an ajax call to handle sign-in user on OCJ modal
     */
    formSubmitHandler: function _formSubmitHandler(event) {
        event.preventDefault();
        var _self = this,
            dataObj = _self.$el.serialize();
            button = $(event.target);
        button.prop('disabled', true).addClass('is-disabled');
        // POST CALL TO SUBMIT DATA
        _self.makeAjaxCall({
            method: 'POST',
            url: _self.$el.attr('action'),
            data: dataObj,
            contentType: 'application/x-www-form-urlencoded',
            success: function(data) {
                if ( !data.nextStateURI) {
                    _self.pubsub.publish('TARGETTED_ERRORMSG_REFRESH',[data.messageIdentifier]);
                    var refreshSignInFormTimeOut = setTimeout(function(){
                        _self.refresh();
                        clearTimeout(refreshSignInFormTimeOut);
                    }, 800);
                    button.prop('disabled', false).removeClass('is-disabled');
                }
            }
        });
        return false;
    },
    /**
     *  This function is for making an ajax call to clear remembered user on OCJ modal
     */
    clearUser : function _clearUser(event) {
        var _self = this,
            target = $(event.target);
        event.preventDefault();
        _self.makeAjaxCall({
            method: 'POST',
            url: target.attr('href'),
            contentType: 'application/x-www-form-urlencoded',
            success: function(data) {
                _self.pubsub.publish('TARGETTED_ERRORMSG_REFRESH',[data.messageIdentifier]);
                _self.refresh();
            }
        });
    },
    /**
     * To highlight the field on one click join modal
     */
    orientationChangeEventHandler: function orientationChangeEventHandler() {
        var _self = this,
            width = $(window).width();
        if((_self.responsiveUtils.isMobile() || (_self.responsiveUtils.isTablet() && _self.responsiveUtils.isPortrait())) && (width < 1024)) {
            if(_self.$el.data('rememberedUser'))
                _self.$el.find('.signin-password').focus();
        }
    },

    facebookAccordionShowHide: function _facebookAccordionShowHide(e) {
        var target = $(e.currentTarget),
        toggleContainer = $(target).siblings('.facebook-message-section');
        
        if (!$(target).hasClass('l-expanded')) {
            toggleContainer.slideDown('slow', function() {
                target.addClass('l-expanded');
                $('.facebook-message-section').removeClass('l-display-none');
                target.attr("aria-expanded","true");
                target.find(".t-icon-arrow-down").removeClass("t-icon-arrow-down").addClass("t-icon-arrow-up");
            })
        }
        else {
            toggleContainer.slideUp('slow', function() {
                target.removeClass('l-expanded');
                $('.facebook-message-section').addClass('l-display-none');
                target.attr("aria-expanded","false");
                target.find(".t-icon-arrow-up").removeClass("t-icon-arrow-up").addClass("t-icon-arrow-down");
            })
        }
    }   
}));
